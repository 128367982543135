import * as React from "react"
import { Link } from "gatsby"
// import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import * as styles from "../components/index.module.css"

const links = [
  {
    text: "Hide your IP",
    url: "#",
    description:
      "A great place to get started if you're new to web development. Designed to guide you through setting up your first Gatsby site.",
  },
  {
    text: "Unlock Streming content",
    url: "#",
    description:
      "Unlock Netflix, HBO, Disney+, HuluTV, and more. No need to travel across the ocean just to watch that geo-restricted Youtube clip. With a single tap, ShellVPN lite changes your online location, making it look like you're from somewhere else.",
  },
  {
    text: "Privacy protection",
    url: "#",
    description:
      "Hide your IP & encrypt your network traffic for a private online experience. With ShellVPN lite, you'll be safe from anyone who wants to peek at your online actions, or worse - intercept your connections.",
  },
  {
    text: "Unlimited bandwith",
    url: "#",
    description:
      "You can use ShellVPN lite in any device without worrying about bandwidth and traffic limitations.",
  },
]


// const moreLinks = [
//   { text: "Join us on Discord", url: "https://gatsby.dev/discord" },
//   {
//     text: "Documentation",
//     url: "#https://gatsbyjs.com/docs/?utm_source=starter&utm_medium=start-page&utm_campaign=default-starter",
//   },
//   {
//     text: "Starters",
//     url: "https://gatsbyjs.com/starters/?utm_source=starter&utm_medium=start-page&utm_campaign=default-starter",
//   },
//   {
//     text: "Showcase",
//     url: "https://gatsbyjs.com/showcase/?utm_source=starter&utm_medium=start-page&utm_campaign=default-starter",
//   },
//   {
//     text: "Contributing",
//     url: "https://www.gatsbyjs.com/contributing/?utm_source=starter&utm_medium=start-page&utm_campaign=default-starter",
//   },
//   { text: "Issues", url: "https://github.com/gatsbyjs/gatsby/issues" },
// ]

const utmParameters = `?utm_source=starter&utm_medium=start-page&utm_campaign=default-starter`

const IndexPage = () => (
  <Layout>
    <Seo title=" Shell VPN lite - Fast VPN service" />
    <div className={styles.textCenter}>
      {/* <StaticImage
        src="../images/example.png"
        loading="eager"
        width={64}
        quality={95}
        formats={["auto", "webp", "avif"]}
        alt=""
        style={{ marginBottom: `var(--space-3)` }}
      /> */}
      <h1>
      <b> Protect internet with ShellVPN lite</b>
      </h1>
      <p className={styles.intro}>
        <br />
        Choose ShellVPN lite as your most suitable network security assistant
      </p>
    </div>
    <ul className={styles.list}>
      {links.map(link => (
        <li key={link.url} className={styles.listItem}>
          <a
            className={styles.listItemLink}
            href={`${link.url}${utmParameters}`}
          >
            {link.text} 
          </a>
          <p className={styles.listItemDescription}>{link.description}</p>
        </li>
      ))}
    </ul>
    {/* {moreLinks.map((link, i) => (
      <React.Fragment key={link.url}>
        <a href={`${link.url}${utmParameters}`}>{link.text}</a>
        {i !== moreLinks.length - 1 && <> · </>}
      </React.Fragment>
    ))} */}
  </Layout>
)

export default IndexPage
